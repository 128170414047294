.tree {
    position: relative;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.branches-container, .leaves-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.branch {
    position: absolute;
    background-color: #654321;
    transform-origin: 0 50%;
    transition: all 0.5s ease-out;
}

.leaf {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #228B22;
    border-radius: 50% 0;
    transform-origin: center;
    transition: all 0.5s ease-out;
}

@keyframes growBranch {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes growLeaf {
    from {
        transform: scale(0) rotate(0deg);
        opacity: 0;
    }

    to {
        transform: scale(1) rotate(var(--rotation));
        opacity: 0.9;
    }
}

/* Add some hover effects for interactivity */
.leaf:hover {
    background-color: #81C784;
    transform: scale(1.2);
    transition: all 0.3s ease;
}

/* Add some color variations to leaves */
.leaf:nth-child(3n) {
    background-color: #66BB6A;
}

.leaf:nth-child(3n+1) {
    background-color: #4CAF50;
}

.leaf:nth-child(3n+2) {
    background-color: #43A047;
}